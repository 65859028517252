import React /* , { useContext } */ from "react";
//import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import NavbarWrapper from "common/components/Navbar";
//import Drawer from "common/components/Drawer";
import Button from "common/components/Button";
import Logo from "common/components/UIElements/Logo";
import Box from "common/components/Box";
//import HamburgMenu from "common/components/HamburgMenu";
import Container from "common/components/UI/Container";
//import { DrawerContext } from "common/contexts/DrawerContext";
//import ScrollSpyMenu from "common/components/ScrollSpyMenu";
import whatsapp from "../../images/whatsapp.svg";
import Image from "common/components/Image";
import LogoImage from "images/smallLogo.png";
import LogoImageAlt from "images/newlogo.jpg";
import MaslolLogo from "images/maslolLogo.png";
import MaslolLogoAlt from "images/test.png";

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  row,
  menuWrapper,
  handlePaymentModal,
  returnPayment = false,
  handleCouponModal,
  lang = "He",
  logo = "DG",
}) => {
  const textLeng = {
    buyBut: { He: "רכוש עכשיו", En: "Buy Now" },
    couponBut: { He: "השתמש בקופון", En: "Have coupon?" },
    whatsappText: { He: "לצ'אט מהיר", En: "Chat With Us" },
  };

  console.log("LOGO", logo);

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            /* href="/saasclassic" */
            href="/"
            logoSrc={logo === "DG" ? LogoImage : MaslolLogoAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            /* href="/saasclassic" */
            href="/"
            logoSrc={logo === "DG" ? LogoImageAlt : MaslolLogo}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            {/* <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.saasClassicJson.MENU_ITEMS}
              offset={-70}
            /> */}
            {/*  <a href="#1" className="navbar_button">
              <Button
                {...button}
                title="רכוש עכשיו"
                onClick={handlePaymentModal}
              />
            </a> */}
            {/* <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            > */}
            {/* <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.saasClassicJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              /> */}
            {returnPayment ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                <a href="https://wa.me/972509341412">
                  <Image
                    style={{
                      width: "28px",
                      margin: "auto",
                      paddingBottom: "4px",
                    }}
                    src={whatsapp}
                    alt="Conntact us via Whatsapp"
                  />
                </a>
                {textLeng["whatsappText"][lang]}
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="#1" className="navbar_drawer_button">
                  <Button
                    {...button}
                    title={textLeng["buyBut"][lang]}
                    onClick={handlePaymentModal}
                  />
                </a>
                {handleCouponModal && (
                  <a
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      color: "#FF282F",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                    onClick={handleCouponModal}
                  >
                    &nbsp;{textLeng["couponBut"][lang]}
                  </a>
                )}
              </div>
            )}

            {/* </Drawer> */}
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["120px", "130px"],
  },
  button: {
    type: "button",
    fontSize: "16px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
    color: "white",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
