import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import Navbar from "Containers/Navbar";

import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
/* import firebaseApp from "../common/components/firebase.js";
import {
  onSnapshot,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore"; */

import Footer from "Containers/Footer";
import AboutUs from "../Containers/AboutUs";

const PaymentReturn = ({ location }) => {
  React.useEffect(() => {}, []);

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Seo
          title={`Dguide | Purchase page}`}
          description={"אישור תשלום"}
          lang={"He"}
          meta={[]}
          keywords={[]}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar returnPayment={true} />
            </DrawerProvider>
          </Sticky>
          <AboutUs />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default PaymentReturn;
